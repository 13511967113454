import {useEffect, useState} from "react";
import './Timer.css'

export const Timer = () => {

    const [days, setDays] = useState(0)
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)

    // const deadline = "November, 15, 2023";
    const deadline = new Date(2023, 10, 14, 23,59, 59);
    const getTime = () => {
        const time = Date.parse(deadline) - Date.now();

        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
    };

    useEffect(() => {
        const interval = setInterval(() => getTime(deadline), 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <h1 className="heading">Surprise Revealing In</h1>
            <div id="countdown" className="countdown">
                <div className="time">
                    <h2 id="days">{days}</h2>
                    <small>Days</small>
                </div>
                <div className="time">
                    <h2 id="hours">{hours}</h2>
                    <small>Hours</small>
                </div>
                <div className="time">
                    <h2 id="minutes">{minutes}</h2>
                    <small>Minutes</small>
                </div>
                <div className="time">
                    <h2 id="seconds">{seconds}</h2>
                    <small>Seconds</small>
                </div>
            </div>
        </>
    )
}
export default Timer