import '../App.css'
import './Portfolio.css'
import {useState} from "react";
import Instagram from '../assets/ig-instagram-icon.svg';
import LinkedIn from '../assets/linkedin-app-icon.svg';
import Threads from '../assets/threads-app-icon.svg';
import TwitterX from '../assets/x-social-media-round-icon.svg';
export const Portfolio = () => {

    const [activePlanet, setActivePlanet] = useState('solar')
    const [zoomClass, setZoomClass] = useState('zoom-large')

    const handleAnchorClick = (e, className)=>{
        console.log(className)
        setActivePlanet(className)
        setZoomClass('zoom-close')
        e.preventDefault()
    }
    const handleSolarView = (e)=>{
        setActivePlanet('solar')
        setZoomClass('zoom-large')
        e.preventDefault()
    }

    return (
        <>
            <div className={`opening view-3D ${zoomClass} data-close controls-close`}>
                <div>
                    <div className="header-main">
                        <span className="header-main__name">Tanisha Vidyarthi</span>
                    </div>
                    {activePlanet === 'solar' && <div className="header-sub">
                        Welcome to the Solar System of my Career, each planet represents a part of my life click to know more!
                    </div>}
                </div>
                <div id="data">
                    <a className={`sun ${activePlanet === 'sun' ? 'active' : ''}`} title="sun" onClick={(e)=> handleAnchorClick(e, 'sun')}>Sun : Bio</a>
                    {/*<a className={`mercury ${activePlanet === 'mercury' ? 'active' : ''}`} title="mercury" onClick={(e)=> handleAnchorClick(e, 'mercury')}>Mercury</a>*/}
                    {/*<a className={`venus ${activePlanet === 'venus' ? 'active' : ''}`} title="venus"  onClick={(e)=> handleAnchorClick(e, 'venus')}>Venus</a>*/}
                    {/*<a className={`earth ${activePlanet === 'earth' ? 'active' : ''}`} title="earth"  onClick={(e)=> handleAnchorClick(e, 'earth')}>Earth</a>*/}
                    {/*<a className={`mars ${activePlanet === 'mars' ? 'active' : ''}`} title="mars" onClick={(e)=> handleAnchorClick(e, 'mars')}>Mars</a>*/}
                    <a className={`jupiter ${activePlanet === 'jupiter' ? 'active' : ''}`} title="jupiter"  onClick={(e)=> handleAnchorClick(e, 'jupiter')}>Jupiter : Education</a>
                    <a className={`saturn ${activePlanet === 'saturn' ? 'active' : ''}`} title="saturn"  onClick={(e)=> handleAnchorClick(e, 'saturn')}>Saturn : Work Experience</a>
                    <a className={`uranus ${activePlanet === 'uranus' ? 'active' : ''}`} title="uranus" onClick={(e)=> handleAnchorClick(e, 'uranus')}>Uranus : Contact Me</a>
                    <a className={`neptune ${activePlanet === 'neptune' ? 'active' : ''}`} title="neptune" onClick={(e)=> handleAnchorClick(e, 'neptune')}>Neptune : Socials</a>
                    <a className={`${activePlanet === 'solar' ? 'active' : ''}`} onClick={handleSolarView}>Solar System : View in Motion</a>
                </div>
                <div id="universe" className="scale-stretched">
                    <div id="galaxy">
                        <div id="solar-system" className={activePlanet}>
                            <div id="mercury" className="orbit">
                                <div className="pos">
                                    <div className="planet">
                                        <dl className="infos">
                                            <dt>Mercury</dt>
                                            <dd><span></span></dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            <div id="venus" className="orbit">
                                <div className="pos">
                                    <div className="planet">
                                        <dl className="infos">
                                            <dt>Venus</dt>
                                            <dd><span></span></dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            <div id="earth" className="orbit">
                                <div className="pos">
                                    <div className="orbit">
                                        <div className="pos">
                                            <div className="moon"></div>
                                        </div>
                                    </div>
                                    <div className="planet">
                                        <dl className="infos">
                                            <dt>Earth</dt>
                                            <dd><span></span></dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            <div id="mars" className="orbit">
                                <div className="pos">
                                    <div className="planet">
                                        <dl className="infos">
                                            <dt>Mars</dt>
                                            <dd><span></span></dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            <div id="jupiter" className="orbit">
                                <div className="pos">
                                    <div className="planet">
                                        <dl className="infos">
                                            <dt>
                                                <div className="education">
                                                    <div className="education-name">Education</div>
                                                    <div className="education-column">
                                                        <div>Lovely Professional University</div>
                                                        <div>2023</div>
                                                    </div>
                                                    <div className="education-column education-university">
                                                        <div>Bachelor's of Technology ( Biotechnology )</div>
                                                        <div>7.6 CGPA</div>
                                                    </div>

                                                </div>

                                            </dt>
                                            <dd><span></span></dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            <div id="saturn" className="orbit">
                                <div className="pos">
                                    <div className="planet">
                                        <div className="ring"></div>
                                        <dl className="infos">
                                            <dt>
                                                <div className="work-experience">
                                                    <div className="work-experience-name">Work Experience</div>
                                                    <div>
                                                        <div className="work-experience__company">
                                                            <div>Graduate Engineer Trainee</div>
                                                            <div className="work-experience__column work-experience__company-name" style={{marginBottom: "12px"}}>
                                                                <div>LTIMindtree</div>
                                                                <div>October 2023 - Present</div>
                                                            </div>

                                                        </div>
                                                        <div className="work-experience__company">
                                                            <div>Inside Sales Manager</div>
                                                            <div className="work-experience__column work-experience__company-name">
                                                                <div>HealthPlix</div>
                                                                <div>September 2022 - February 2023</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </dt>
                                            <dd><span></span></dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            <div id="uranus" className="orbit">
                                <div className="pos">
                                    <div className="planet">
                                        <dl className="infos">
                                            <dt>
                                                <div className="contact-me">
                                                    <div className="contact-me__name">Contact Me</div>
                                                    <div>
                                                        <div>
                                                            T: +91 - 9019715259
                                                        </div>
                                                        <div className="contact-me__mail">
                                                            E: <a href="mailto:hello@tanishavidyarthi.com">hello@tanishavidyarthi.com</a>
                                                        </div>
                                                        <div className="contact-me__contact-form">
                                                            <span>Contact Form Coming Soon to ease your life!!</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </dt>
                                            <dd><span></span></dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            <div id="neptune" class="orbit">
                                <div class="pos">
                                    <div class="planet">
                                        <dl class="infos">
                                            <dt>
                                                <div className="socials">
                                                    <div className="socials-name">Follow Me</div>
                                                    <div className="socials-column">
                                                        <div>
                                                            <a href="https://www.linkedin.com/in/tanishavidyarthi/" target="_blank" rel="noreferrer"><img src={LinkedIn} className="socials-img" alt="LinkedIn"/></a>

                                                        </div>
                                                        <div>
                                                            <a href="https://www.instagram.com/tanishavidyarthi" target="_blank" rel="noreferrer">
                                                                <img src={Instagram} alt="Instagram" className="socials-img"/>
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a href="https://www.threads.net/tanishavidyarthi" target="_blank" rel="noreferrer">
                                                                <img src={Threads} alt="Threads" className="socials-img"/>
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a href="https://twitter.com/Tanisha_V15" target="_blank" rel="noreferrer"><img src={TwitterX} alt="TwitterX" className="socials-img"/></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </dt>
                                            <dd><span></span></dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                            <div id="sun">
                                <dl class="infos">
                                    <dt className="bio-block">
                                        <div className="bio">
                                            <div className="bio-name">Tanisha Vidyarthi</div>
                                            <div>Software Engineer</div>
                                            <div>Aspiring Product Manager</div>
                                        </div>
                                    </dt>
                                    <dd><span></span></dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio
