import Portfolio from "./components/Portfolio";
import Countdown from "./components/Countdown";
import {useEffect, useState} from "react";
function App() {

  const [showPortfolio, setShowPortfolio] = useState(false)
  // const deadline = "November, 15, 2023 20:42:59";
  const deadline = new Date(2023, 10, 14, 23,59, 59);
  // const deadline = new Date(2023, 11, 14, 20, 38, 59).getTime()

  const getTime = () => {
    const time = Date.parse(deadline) <= Date.now();
    if (time){
      setShowPortfolio(time)
    }

  };

  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);

    return () => clearInterval(interval);
  }, []);

  return (
      <>
        {showPortfolio ? <Portfolio/> : <Countdown/>}
      </>
  );
}

export default App;
