import Video from '../assets/pexels_videos_1877846.mp4'
import './Countdown.css'
import Timer from "./Timer";

// this is just to push new changes
export const Countdown = () => {
    return (
        <div>
            <video id="background-video" src={Video} autoPlay="true" loop="true" playsInline="true" muted="true"/>
            <Timer/>
        </div>
    )
}

export default Countdown
